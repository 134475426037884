import * as S from "./styles";
import { STRAVA_OAUTH_URL } from "../../../constants";
import Play9StravaLogo from "../../atoms/Play9StravaLogo";

const LoginPageContent: React.FC = () => {
  return (
    <S.LoginPageContent>
      <S.Title>Boas vindas ao Play9 Run</S.Title>

      <S.Description>O grupo de corridas e caminhadas da Play9.</S.Description>

      <Play9StravaLogo />

      <S.Label>
        Conecte sua conta Strava para contabilizar suas atividades nos nossos
        desafios.
      </S.Label>

      <S.Button href={STRAVA_OAUTH_URL}>Entrar com a conta do Strava</S.Button>
    </S.LoginPageContent>
  );
};

export default LoginPageContent;
