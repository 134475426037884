import * as S from "./styles";

const Play9StravaLogo: React.FC = () => {
  return (
    <S.Play9StravaLogo>
      <S.Play9Logo />

      <S.StravaLogo />
    </S.Play9StravaLogo>
  );
};

export default Play9StravaLogo;
