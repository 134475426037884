import styled from "styled-components";

export const Page = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  align-self: center;
  flex-direction: column;
  background-color: #f8f8f8;
`;
