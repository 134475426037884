import * as S from "./styles";

interface IPage {
  children: React.ReactNode;
}

const Page: React.FC<IPage> = ({ children }) => {
  return (
    <S.Page>
      <S.Container>{children}</S.Container>
    </S.Page>
  );
};

export default Page;
