import styled from "styled-components";

export const HomePageHeaderContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: 48px;
  flex-direction: column;

  @media (max-width: 460px) {
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  margin: 0;
  color: white;
  margin-top: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;

  @media (max-width: 460px) {
    width: 100%;
    font-size: 20px;
    margin-top: 16px;
  }
`;

export const Description = styled.p`
  margin-top: 4px;
  color: white;
  opacity: 0.7;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;

  @media (max-width: 460px) {
    width: 100%;
    font-size: 14px;
  }
`;
