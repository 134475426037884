import styled, { css } from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 96px;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  animation: colors 15s ease infinite forwards;

  @media (max-width: 960px) {
    padding: 20px;
  }

  @media (max-width: 460px) {
    padding: 20px 12px;
  }

  @keyframes colors {
    0% {
      background-color: #fd9909;
    }
    50% {
      background-color: #fe6120;
    }
    100% {
      background-color: #fd9909;
    }
  }
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Play9Logo = styled.div`
  width: 120px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url("/play9-logo-branco.svg");
`;

export const Totalizers = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 680px;
  margin-top: 64px;
  align-self: center;

  @media (max-width: 460px) {
    gap: 8px;
    padding: 0 12px;
  }
`;

export const UserAvatar = styled.button<{ img: string; isClicked: boolean }>`
  ${({ img, isClicked }) => css`
    width: ${isClicked ? "120px" : "48px"};
    height: 48px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    position: relative;
    padding-right: 32px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.4);

    &::after {
      top: 0;
      left: 0;
      z-index: 1;
      content: "";
      width: 48px;
      height: 48px;
      position: absolute;
      border-radius: 40px;
      background-color: #ddd;
      background-size: cover;
      background-position: center;
      background-image: url(${img});
    }

    @media (max-width: 460px) {
      width: ${isClicked ? "100px" : "40px"};
      height: 40px;
      padding-right: 22px;

      &::after {
        width: 40px;
        height: 40px;
      }
    }
  `}
`;
