import styled, { css } from "styled-components";

export const Pill = styled.small<{ color: string }>`
  ${({ color }) => css`
    color: white;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 80px;
    background-color: ${color};
    padding: 2px 12px 1px 12px;
  `}
`;
