import styled from "styled-components";

export const HomePageBody = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  max-width: 680px;
  align-self: center;
  padding-bottom: 80px;
  flex-direction: column;

  @media (max-width: 960px) {
    max-width: 960px;
  }

  @media (max-width: 460px) {
    padding: 12px;
    margin-top: 12px;
    padding-bottom: 80px;
  }
`;

export const AthletesCount = styled.h2`
  color: #fe6120;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;

  @media (max-width: 460px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const AthletesList = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: 460px) {
    gap: 6px;
  }
`;
