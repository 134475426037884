import * as S from "./styles";

interface ITotalizers {
  totalTime: string;
  totalDistance: string;
  activitiesCount: number;
}

const Totalizers: React.FC<ITotalizers> = ({
  totalTime,
  totalDistance,
  activitiesCount,
}) => {
  return (
    <S.Totalizers>
      <S.Total>
        <S.TotalValue>{activitiesCount}</S.TotalValue>

        <S.TotalLabel>atividades</S.TotalLabel>
      </S.Total>

      <S.Total>
        <S.TotalValue>{totalDistance}</S.TotalValue>

        <S.TotalLabel>distância</S.TotalLabel>
      </S.Total>

      <S.Total>
        <S.TotalValue>{totalTime}</S.TotalValue>

        <S.TotalLabel>em movimento</S.TotalLabel>
      </S.Total>
    </S.Totalizers>
  );
};

export default Totalizers;
