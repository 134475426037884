import Auth from "../context/auth";
import PublicRoutes from "./public";
import PrivateRoutes from "./private";
import { BrowserRouter } from "react-router-dom";

const Router: React.FC = () => {
  const { isLogged } = Auth.useAuth();

  return (
    <BrowserRouter>
      {isLogged ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};

export default Router;
