import Page from "../../atoms/Page";
import { auth } from "../../../services";
import Auth from "../../../context/auth";
import AppLogo from "../../atoms/AppLogo";
import Loading from "../../atoms/Loading";
import { useEffect, useState } from "react";
import LoginPageHeader from "../../molecules/LoginPageHeader";
import LoginPageContent from "../../molecules/LoginPageContent";
import { INVALID_SCOPE_ERROR_MESSAGE } from "../../../constants";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { login } = Auth.useAuth();

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);

        const code = new URLSearchParams(window.location.search).get("code");
        const scope = new URLSearchParams(window.location.search).get("scope");

        if (!code) return;

        if (scope !== "read,activity:read_all,profile:read_all")
          return alert(INVALID_SCOPE_ERROR_MESSAGE);

        const user = await auth(code);

        login(user);
      } catch (error) {
        alert("Houve um erro ao conectar sua conta, tente novamente.");
      } finally {
        setLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <LoginPageHeader />

      <AppLogo />

      <LoginPageContent />

      {loading ? <Loading /> : null}
    </Page>
  );
};

export default Login;
