import styled from "styled-components";

export const Loading = styled.div`
  @-webkit-keyframes ball-scale-multiple {
    0% {
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @keyframes ball-scale-multiple {
    0% {
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  backdrop-filter: blur(8px);
  background-color: #ffffffaa;

  div:nth-child(2) {
    animation-delay: -0.4s;
    background-color: #e17032;
    -webkit-animation-delay: -0.4s;
  }

  div:nth-child(3) {
    animation-delay: -0.2s;
    background-color: #de357a;
    -webkit-animation-delay: -0.2s;
  }

  div {
    margin: 0;
    opacity: 0;
    width: 15px;
    margin: 2px;
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 100%;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    animation-fill-mode: both;
    background-color: #e6a33a;
    -webkit-animation-fill-mode: both;
    animation: ball-scale-multiple 1s 0s linear infinite;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
  }
`;
