import styled from "styled-components";

export const LoginPageContent = styled.div`
  width: 100%;
  display: flex;
  max-width: 540px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 80px 20px;

  @media (max-width: 460px) {
    padding: 24px 12px 80px 12px;
  }
`;

export const Title = styled.h1`
  color: #333;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 130%;

  @media (max-width: 520px) {
    font-size: 28px;
  }

  @media (max-width: 460px) {
    font-size: 24px;
  }
`;

export const Description = styled.p`
  color: #666;
  font-size: 18px;
  margin-top: 8px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;

  @media (max-width: 520px) {
    font-size: 16px;
  }

  @media (max-width: 460px) {
    font-size: 14px;
    margin-top: 4px;
  }
`;

export const Button = styled.a`
  width: 100%;
  color: #fff;
  height: 60px;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  margin-top: 32px;
  max-width: 360px;
  font-weight: 400;
  text-align: center;
  align-items: center;
  border-radius: 18px;
  text-decoration: none;
  justify-content: center;
  background-color: #fc6100;

  @media (max-width: 460px) {
    height: 56px;
    font-size: 14px;
    max-width: 320px;
  }
`;

export const Label = styled.small`
  color: #666;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  max-width: 360px;
  margin-top: 40px;
  line-height: 140%;
  text-align: center;

  @media (max-width: 520px) {
    font-size: 14px;
    margin-top: 20px;
  }

  @media (max-width: 460px) {
    font-size: 12px;
  }
`;
