import styled from "styled-components";

export const AppLogo = styled.div`
  z-index: 2;
  width: 240px;
  height: 240px;
  border-radius: 2px;
  margin-top: -120px;
  align-self: center;
  position: relative;
  background-size: cover;
  border: 3px solid white;
  background-color: #fd9909;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/logo.png");
  box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.2);

  @media (max-width: 520px) {
    width: 200px;
    height: 200px;
  }
`;
