import ReactDOM from "react-dom/client";

import Router from "./router";
import Auth from "./context/auth";
import { GlobalStyle } from "./globalStyles";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <>
    <GlobalStyle />

    <Auth.Provider>
      <Router />
    </Auth.Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
