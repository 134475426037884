import styled, { css } from "styled-components";

export const PageHeader = styled.div<{ color: string }>`
  width: 100%;
  height: 100px;
  position: relative;
  background-color: ${({ color }) => color};
`;

export const AthleteInfo = styled.div`
  z-index: 2;
  display: flex;
  margin-top: -80px;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

export const AthleteName = styled.h1`
  color: #333;
  font-size: 28px;
  margin-top: 12px;
  font-weight: 600;
  line-height: 130%;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 20px;
  }
`;

export const LargeAvatar = styled.div<{ img: string }>`
  ${({ img }) => css`
    width: 160px;
    height: 160px;
    border-radius: 200px;
    background-size: cover;
    border: 3px solid white;
    background-color: #fd9909;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${img});
    box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.2);
  `}

  @media (max-width: 460px) {
    width: 140px;
    height: 140px;
    border-width: 2px;
  }
`;

export const Play9Symbol = styled.div`
  width: 40px;
  height: 40px;
  right: 20px;
  bottom: 12px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/play9-icone.svg");
`;

export const LinkToStrava = styled.a`
  color: #074899;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  text-transform: none;
  text-decoration: underline;

  @media (max-width: 460px) {
    font-size: 12px;
    margin-top: 6px;
  }
`;
