import styled from "styled-components";

export const Totalizers = styled.div`
  gap: 12px;
  display: flex;

  @media (max-width: 460px) {
    gap: 8px;
  }
`;

export const Total = styled.div`
  display: flex;
  color: white;
  width: 33.333%;
  padding: 20px 12px;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 40px -24px rgba(0, 0, 0, 0.2);

  @media (max-width: 460px) {
    padding: 16px 4px;
  }

  &:nth-of-type(1) {
    background-color: #074899;
  }

  &:nth-of-type(2) {
    background-color: #fd9909;
  }

  &:nth-of-type(3) {
    background-color: #fa3a34;
  }
`;

export const TotalValue = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 14px;
  }
`;

export const TotalLabel = styled.p`
  font-size: 14px;
  margin-top: 2px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 11px;
  }
`;
