import * as S from "./styles";
import Page from "../../atoms/Page";
import Auth from "../../../context/auth";
import Loading from "../../atoms/Loading";
import Totalizers from "../../atoms/Totalizers";
import { useEffect, useRef, useState } from "react";
import HomePageBody from "../../molecules/HomePageBody";
import { getCurrentChallenge } from "../../../services";
import {
  currentMonthPercentConcluded,
  formatDistanceInKilometers,
  formatTimeInHours,
} from "../../../utils";
import { ICurrentChallengeResponse } from "../../../types/api";
import HomePageHeaderContent from "../../molecules/HomePageHeaderContent";

const todayGoal = currentMonthPercentConcluded();

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAvatarClicked, setIsAvatarClicked] = useState(false);
  const [data, setData] = useState<ICurrentChallengeResponse | null>(null);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const { user, logout } = Auth.useAuth();

  useEffect(() => {
    const run = async () => {
      try {
        const response = await getCurrentChallenge();
        setData(response);
      } catch (error) {
        alert("Houve um erro ao buscar os dados do desafio.");
      } finally {
        setIsLoading(false);
      }
    };

    run();
  }, []);

  const onAvatarClickHandler = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (isAvatarClicked) {
      return logout();
    }

    setIsAvatarClicked(true);

    timeout.current = setTimeout(() => {
      setIsAvatarClicked(false);
    }, 3000);
  };

  const pendingDistance = (() => {
    if (!data) return 0;

    if (data.goal < data.totalDistance / 1000) return 0;

    return data.goal - data.totalDistance / 1000;
  })();

  const finalPercentage =
    (data?.percentAchieved || 0) > 100 ? 100 : data?.percentAchieved || 0;

  const pendingDays = data?.end_date
    ? new Date(data.end_date).getDate() - new Date().getDate()
    : 30;

  return (
    <Page>
      <S.Header>
        <S.Head>
          <S.Play9Logo />

          <S.UserAvatar
            img={user?.profile || ""}
            isClicked={isAvatarClicked}
            onClick={onAvatarClickHandler}
          >
            {isAvatarClicked ? "Sair" : ""}
          </S.UserAvatar>
        </S.Head>

        <HomePageHeaderContent
          todayGoal={todayGoal}
          goal={data?.goal || 0}
          name={data?.name || ""}
          pendingDays={pendingDays}
          finalPercentage={finalPercentage}
          pendingDistance={pendingDistance}
          description={data?.description || ""}
          totalDistance={data?.totalDistance || 0}
          percentAchieved={data?.percentAchieved || 0}
        />
      </S.Header>

      <S.Totalizers>
        <Totalizers
          activitiesCount={data?.activitiesCount || 0}
          totalTime={formatTimeInHours(data?.totalMovingTime || 0, true)}
          totalDistance={formatDistanceInKilometers(
            data?.totalDistance || 0,
            0,
          )}
        />
      </S.Totalizers>

      <HomePageBody
        athletes={data?.athletes || []}
        userId={user?.id?.toString() || ""}
      />

      {isLoading ? <Loading /> : null}
    </Page>
  );
};

export default Home;
