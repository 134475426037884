import styled from "styled-components";

export const Play9StravaLogo = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;

  @media (max-width: 520px) {
    margin-top: 20px;
  }
`;

export const Play9Logo = styled.div`
  width: 180px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/play9-logo.svg");

  @media (max-width: 520px) {
    width: 100px;
  }
`;

export const StravaLogo = styled.div`
  width: 160px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/strava-logo.svg");

  @media (max-width: 520px) {
    width: 120px;
  }
`;
