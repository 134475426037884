import {
  formatPace,
  formatTimeInHours,
  normalizeActivityType,
  formatDistanceInKilometers,
} from "../../../utils";
import * as S from "./styles";
import Page from "../../atoms/Page";
import Loading from "../../atoms/Loading";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAthlete } from "../../../services";
import Totalizers from "../../atoms/Totalizers";
import ActivityCard from "../../atoms/ActivityCard";
import { IGetAthleteResponse } from "../../../types/api";
import AthletePageHeader from "../../molecules/AthletePageHeader";

const Athlete: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState<"distance" | "date">("date");
  const [activities, setActivities] = useState<
    IGetAthleteResponse["activities"]
  >([]);
  const [athlete, setAthlete] = useState<IGetAthleteResponse["athlete"] | null>(
    null,
  );

  const { id } = useParams();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      try {
        const { activities, athlete } = await getAthlete(id);

        setActivities(activities);
        setAthlete(athlete);
      } catch (error) {
        alert("Houve um erro ao obter os dados do atleta");
      } finally {
        setIsLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalDistance = activities.reduce(
    (acc, activity) => acc + (activity.distance || 0),
    0,
  );

  const totalTime = activities.reduce(
    (acc, activity) => acc + (activity.movingTime || 0),
    0,
  );

  const athleteName =
    (athlete?.firstname || "") + " " + (athlete?.lastname || "");

  const athleteProfileImage =
    athlete?.profileImage?.replace("medium", "original") || "";

  const sortedActivities = activities.sort((a, b) => {
    if (sortBy === "distance") {
      return (b.distance || 0) - (a.distance || 0);
    }

    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    );
  });

  return (
    <Page>
      <AthletePageHeader
        athleteId={id || ""}
        athleteName={athleteName}
        atheleteProfileImage={athleteProfileImage}
      />

      <S.Totalizers>
        <Totalizers
          activitiesCount={activities.length}
          totalTime={formatTimeInHours(totalTime)}
          totalDistance={formatDistanceInKilometers(totalDistance)}
        />
      </S.Totalizers>

      <S.Options>
        <S.Sort>
          Ordenar por:
          <S.Button
            highlight={sortBy === "distance"}
            onClick={() => setSortBy("distance")}
          >
            distância
          </S.Button>
          <S.Button
            highlight={sortBy === "date"}
            onClick={() => setSortBy("date")}
          >
            data
          </S.Button>
        </S.Sort>
      </S.Options>

      <S.ActivityCards>
        {sortedActivities.map((activity) => (
          <ActivityCard
            key={activity.id}
            activityId={String(activity.id)}
            averageSpeed={formatPace(activity.averageSpeed)}
            movingTime={formatTimeInHours(activity.movingTime)}
            activityType={normalizeActivityType(activity.sportType)}
            distance={formatDistanceInKilometers(activity.distance)}
            date={new Date(activity.startDate || "")
              .toLocaleString("pt-BR")
              .substring(0, 17)
              .replace(",", " às")}
          />
        ))}
      </S.ActivityCards>

      {isLoading ? <Loading /> : null}
    </Page>
  );
};

export default Athlete;
