import * as S from "./styles";
import { normalizeStravaLocation } from "../../../utils";

interface IAthleteCard {
  name: string;
  location: string;
  athleteId: string;
  profileImage: string;
  totalDistance: string;
  isLoggedUser: boolean;
  activitiesCount: number;
}

const AthleteCard: React.FC<IAthleteCard> = ({
  name,
  location,
  athleteId,
  profileImage,
  isLoggedUser,
  totalDistance,
  activitiesCount,
}) => {
  const activitiesLabel =
    activitiesCount === 1 ? "1 atividade" : `${activitiesCount} atividades`;

  return (
    <S.AthleteCard href={`/athelete/${athleteId}`}>
      <S.AthleteAvatar img={profileImage} />

      <S.AthleteInfo>
        <S.AthleteName>
          <span>{isLoggedUser ? "★ " : ""}</span>

          {name}
        </S.AthleteName>

        <S.AthleteLocation>
          {normalizeStravaLocation(location)}
        </S.AthleteLocation>
      </S.AthleteInfo>

      <S.AthleteProgress>
        <S.AthleteDistance>{totalDistance}</S.AthleteDistance>

        <S.AthleteCount>{activitiesLabel}</S.AthleteCount>
      </S.AthleteProgress>
    </S.AthleteCard>
  );
};

export default AthleteCard;
