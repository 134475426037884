import styled from "styled-components";

export const LoginPageHeader = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  background-image: linear-gradient(#fea812, #d60147);

  @media (max-width: 520px) {
    height: 160px;
  }
`;

export const Play9Symbol = styled.div`
  width: 40px;
  height: 40px;
  right: 20px;
  bottom: 12px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/play9-icone.svg");
`;
