import * as S from "./styles";

interface IActivityCardProps {
  distance: string;
  date: string;
  activityId: string;
  movingTime: string;
  averageSpeed: string;
  activityType: string;
}

const ActivityCard: React.FC<IActivityCardProps> = ({
  date,
  distance,
  movingTime,
  activityId,
  activityType,
  averageSpeed,
}) => {
  return (
    <S.ActivityCard>
      <S.ActivityHeader>
        <S.HeaderInfo>
          <S.ActivityType>
            <S.Icon type={activityType.toLowerCase()} />
            {activityType}
          </S.ActivityType>

          <S.Date>{date}</S.Date>
        </S.HeaderInfo>

        <S.Link
          target="_blank"
          href={`https://strava.com/activities/${activityId}`}
        >
          Ver no Strava
        </S.Link>
      </S.ActivityHeader>

      <S.Contribution>
        <S.ContributionValue>{`+ ${distance}`}</S.ContributionValue>

        <S.ContributionLabel>para o desafio</S.ContributionLabel>
      </S.Contribution>

      <S.ActivityDetails>
        <S.Detail>
          <S.Value>{distance}</S.Value>

          <S.Label>distância</S.Label>
        </S.Detail>

        <S.Detail>
          <S.Value>{movingTime}</S.Value>

          <S.Label>duração</S.Label>
        </S.Detail>

        <S.Detail>
          <S.Value>{averageSpeed}</S.Value>

          <S.Label>pace</S.Label>
        </S.Detail>
      </S.ActivityDetails>
    </S.ActivityCard>
  );
};

export default ActivityCard;
