import * as S from "./styles";

const LoginPageHeader: React.FC = () => {
  return (
    <S.LoginPageHeader>
      <S.Play9Symbol />
    </S.LoginPageHeader>
  );
};

export default LoginPageHeader;
