import * as S from "./styles";

interface IAthletePageHeader {
  athleteId: string;
  athleteName: string;
  atheleteProfileImage: string;
}

const randomColor = ((): string => {
  const colors = [
    "#FD9909",
    "#DE0648",
    "#074899",
    "#FA3A34",
    "#00A0DC",
    "#31C26E",
    "#FE6120",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
})();

const AthletePageHeader: React.FC<IAthletePageHeader> = ({
  athleteId,
  athleteName,
  atheleteProfileImage,
}) => {
  return (
    <>
      <S.PageHeader color={randomColor}>
        <S.Play9Symbol />
      </S.PageHeader>

      <S.AthleteInfo>
        <S.LargeAvatar img={atheleteProfileImage} />

        <S.AthleteName>{athleteName}</S.AthleteName>

        <S.LinkToStrava
          target="_blank"
          href={`https://strava.com/athletes/${athleteId}`}
        >
          Ver no Strava
        </S.LinkToStrava>
      </S.AthleteInfo>
    </>
  );
};

export default AthletePageHeader;
