import * as S from "./styles";
import HomePagePills from "../HomePagePills";
import HomePageProgressBar from "../HomePageProgressBar";

interface IHomePageHeaderContent {
  name: string;
  goal: number;
  todayGoal: number;
  pendingDays: number;
  description: string;
  totalDistance: number;
  finalPercentage: number;
  percentAchieved: number;
  pendingDistance: number;
}

const HomePageHeaderContent: React.FC<IHomePageHeaderContent> = ({
  name,
  goal,
  todayGoal,
  description,
  pendingDays,
  totalDistance,
  finalPercentage,
  pendingDistance,
  percentAchieved,
}) => {
  return (
    <S.HomePageHeaderContent>
      <HomePagePills
        pendingDays={pendingDays}
        pendingDistance={pendingDistance}
        percentAchieved={percentAchieved}
      />

      <S.Title>{name}</S.Title>

      <S.Description>{description}</S.Description>

      <HomePageProgressBar
        totalGoal={goal}
        todayGoal={todayGoal}
        totalDistance={totalDistance}
        finalPercentage={finalPercentage}
        pendingDistance={pendingDistance}
      />
    </S.HomePageHeaderContent>
  );
};

export default HomePageHeaderContent;
