import styled from "styled-components";

export const Pills = styled.div`
  gap: 4px;
  row-gap: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;
