import * as S from "./styles";
import { PropsWithChildren } from "react";

interface IPill extends PropsWithChildren {
  color?: string;
}

const Pill: React.FC<IPill> = ({ color, children }) => {
  return <S.Pill color={color || "black"}>{children}</S.Pill>;
};

export default Pill;
