import styled, { css } from "styled-components";

export const ActivityCard = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 640px;
  border-radius: 8px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 40px -24px rgba(0, 0, 0, 0.2);

  @media (max-width: 460px) {
    padding: 16px;
  }
`;

export const ActivityHeader = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

export const HeaderInfo = styled.div`
  gap: 4px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div<{ type: string }>`
  ${({ type }) => css`
    width: 18px;
    height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${{
      corrida: "/run-icon.svg",
      caminhada: "/walk-icon.svg",
    }[type] || "/heart-icon.svg"});
  `}
`;

export const Link = styled.a`
  color: #074899;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  text-transform: none;
  text-decoration: underline;

  @media (max-width: 460px) {
    font-size: 11px;
  }
`;

export const ActivityType = styled.h3`
  gap: 6px;
  color: #333;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
  align-items: center;

  @media (max-width: 460px) {
    font-size: 16px;
  }
`;

export const Date = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;

  @media (max-width: 460px) {
    font-size: 12px;
  }
`;

export const Contribution = styled.div`
  z-index: 1;
  padding-top: 20px;
  position: relative;
  border-top: 1px solid #ddd;
`;

export const ContributionValue = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 20px;
  }
`;

export const ContributionLabel = styled.p`
  color: #777;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;

  @media (max-width: 460px) {
    margin-top: 2px;
    font-size: 12px;
  }
`;

export const ActivityDetails = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

export const Value = styled.p`
  width: 100%;
  height: 32px;
  display: flex;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  border-radius: 80px;
  justify-content: center;

  @media (max-width: 460px) {
    font-size: 12px;
  }
`;

export const Detail = styled.div`
  gap: 2px;
  flex-grow: 1;
  display: flex;
  width: 33.333%;
  align-items: center;
  flex-direction: column;

  &:nth-of-type(1) {
    ${Value} {
      background-color: #fd9909;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:nth-of-type(2) {
    ${Value} {
      border-radius: 0;
      background-color: #fa3a34;
    }
  }
  &:nth-of-type(3) {
    ${Value} {
      background-color: #31c26e;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const Label = styled.small`
  color: #333;
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 11px;
  }
`;
