import styled, { css } from "styled-components";

export const AthleteCard = styled.a`
  gap: 20px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  text-transform: none;
  text-decoration: none;
  background-color: white;
  padding: 16px 32px 16px 16px;
  box-shadow: 0 0 40px -24px rgba(0, 0, 0, 0.2);

  @media (max-width: 540px) {
    gap: 12px;
    border-radius: 20px;
  }

  @media (max-width: 460px) {
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
  }
`;

export const AthleteAvatar = styled.div<{ img: string }>`
  ${({ img }) => css`
    width: 88px;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${img});

    @media (max-width: 540px) {
      width: 72px;
      height: 72px;
      border-radius: 10px;
    }

    @media (max-width: 380px) {
      width: 64px;
      height: 64px;
      border-radius: 8px;
    }
  `}
`;

export const AthleteInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AthleteProgress = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const AthleteName = styled.h4`
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height: 130%;

  span {
    color: #fe6120;
  }

  @media (max-width: 540px) {
    font-size: 16px;
  }
`;

export const AthleteLocation = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 540px) {
    font-size: 12px;
  }

  @media (max-width: 460px) {
    font-size: 10px;
  }
`;

export const AthleteDistance = styled.h4`
  color: #333;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 540px) {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }
`;

export const AthleteCount = styled.small`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

  @media (max-width: 540px) {
    font-size: 12px;
  }

  @media (max-width: 460px) {
    font-size: 10px;
  }
`;
