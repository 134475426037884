import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: none;
    letter-spacing: 0px;
    background: none;
    box-sizing: border-box;
    transition: all ease 0.2s;
  }

  html {
    overflow: overlay;
  }
`;
