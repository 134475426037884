import Pill from "../../atoms/Pill";
import * as S from "./styles";

interface IHomePagePills {
  pendingDays: number;
  pendingDistance: number;
  percentAchieved: number;
}

const HomePagePills: React.FC<IHomePagePills> = ({
  pendingDays,
  pendingDistance,
  percentAchieved,
}) => {
  const pills = [
    {
      color: "#00a0dc",
      key: "percent_achieved",
      label: `${percentAchieved?.toFixed(0)}% concluído`,
    },
    {
      color: "#074899",
      key: "pending_days",
      label:
        pendingDays === 1
          ? "1 dia para acabar"
          : `${pendingDays} dias para acabar`,
    },
    {
      color: "#31C26E",
      key: "pending_distance",
      label: `Faltam ${pendingDistance.toFixed(0)}km`,
    },
  ];

  return (
    <S.Pills>
      {pills.map((pill) => (
        <Pill key={pill.key} color={pill.color}>
          {pill.label}
        </Pill>
      ))}
    </S.Pills>
  );
};

export default HomePagePills;
