import * as S from "./styles";
import AthleteCard from "../../atoms/AthleteCard";
import { ICurrentChallengeResponse } from "../../../types/api";
import { formatDistanceInKilometers } from "../../../utils";

interface IHomePageBody {
  userId: string;
  athletes: ICurrentChallengeResponse["athletes"];
}

const HomePageBody: React.FC<IHomePageBody> = ({ userId, athletes }) => {
  const totalAthletesLabel =
    athletes.length === 1
      ? "1 atleta participando"
      : `${athletes.length} atletas participando`;

  return (
    <S.HomePageBody>
      <S.AthletesCount>{totalAthletesLabel}</S.AthletesCount>

      <S.AthletesList>
        {athletes.map((athlete) => (
          <AthleteCard
            name={athlete.name}
            key={athlete.athleteId}
            location={athlete.location}
            athleteId={athlete.athleteId}
            profileImage={athlete.profileImage}
            activitiesCount={athlete.activitiesCount}
            isLoggedUser={athlete.athleteId === userId}
            totalDistance={formatDistanceInKilometers(athlete.totalDistance)}
          />
        ))}
      </S.AthletesList>
    </S.HomePageBody>
  );
};

export default HomePageBody;
