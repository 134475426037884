import { TSportType } from "../types/sportType";

export const currentMonthPercentConcluded = (): number => {
  const now = new Date();

  const daysInMonth = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0,
  ).getDate();

  const today = now.getDate();

  return Math.round((today / daysInMonth) * 100);
};

export const normalizeStravaLocation = (location: string): string => {
  if (!location || location === "null") {
    return "";
  }

  return location.replace("State of", "");
};

export const formatDistanceInKilometers = (
  distanceInMeters?: number,
  digits?: number,
): string => {
  if (!distanceInMeters) return "0km";

  if (distanceInMeters < 1000)
    return `${distanceInMeters.toFixed(0).replace(".", ",")}m`;

  const normalized = (distanceInMeters / 1000)
    .toFixed(typeof digits === "number" ? digits : 2)
    .replace(".", ",");

  return `${normalized}km`;
};

export const formatTimeInHours = (
  timeInSeconds?: number,
  onlyHours?: boolean,
): string => {
  if (!timeInSeconds || timeInSeconds < 60) return "0min";

  const timeInMinutes = timeInSeconds / 60;

  if (timeInMinutes < 60) return `${Math.floor(timeInMinutes)}min`;

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = Math.floor(timeInMinutes % 60);

  const normalizedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  if (onlyHours) return `${hours}h`;

  return `${hours}h ${normalizedMinutes}min`;
};

export const formatPace = (velocityInMetersPerSeconds?: number): string => {
  if (!velocityInMetersPerSeconds) return "0min/km";

  const velocityInKilometersPerHour = velocityInMetersPerSeconds * 3.6;

  const paceInMinutes = velocityInKilometersPerHour
    ? 60 / velocityInKilometersPerHour
    : 0;

  const minutes = Math.floor(paceInMinutes);
  const seconds = Math.floor((paceInMinutes - minutes) * 60);

  const normalizedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${normalizedSeconds}min/km`;
};

export const normalizeActivityType = (type?: TSportType): string => {
  if (!type) return "Outro";

  const parser: { [key in TSportType]: string } = {
    AlpineSki: "Esqui",
    BackcountrySki: "Esqui",
    Badminton: "Badminton",
    Canoeing: "Canoagem",
    Crossfit: "Crossfit",
    EBikeRide: "Pedalada",
    Elliptical: "Elíptico",
    EMountainBikeRide: "Pedalada",
    Golf: "Golf",
    GravelRide: "Pedalada",
    Handcycle: "Pedalada",
    HighIntensityIntervalTraining: "HIIT",
    Hike: "Caminhada",
    IceSkate: "Patinação",
    InlineSkate: "Patinação",
    Kayaking: "Canoagem",
    Kitesurf: "Surfe",
    MountainBikeRide: "Pedalada",
    NordicSki: "Esqui",
    Pickleball: "Padel",
    Pilates: "Pilates",
    Racquetball: "Tênis",
    Ride: "Pedalada",
    RockClimbing: "Escalada",
    RollerSki: "Esqui",
    Rowing: "Canoagem",
    Run: "Corrida",
    Sail: "Velejo",
    Skateboard: "Skate",
    Snowboard: "Esqui",
    Snowshoe: "Esqui",
    Soccer: "Futebol",
    Squash: "Squash",
    StairStepper: "Simulador de Escadas",
    StandUpPaddling: "Surfe",
    Surfing: "Surfe",
    Swim: "Natação",
    TableTennis: "Tênis de Mesa",
    Tennis: "Tênis",
    TrailRun: "Corrida",
    Velomobile: "Skate",
    VirtualRide: "Pedalada",
    VirtualRow: "Canoagem",
    VirtualRun: "Corrida",
    Walk: "Caminhada",
    WeightTraining: "Musculação",
    Wheelchair: "Cadeira de Rodas",
    Windsurf: "Surfe",
    Workout: "Musculação",
    Yoga: "Yoga",
  };

  return parser[type] || "Outro";
};
