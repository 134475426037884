import styled, { css } from "styled-components";

export const HomePageProgressBar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  margin-top: 48px;
  position: relative;
  border-radius: 28px;
  border: 6px solid white;
  background-color: white;
  transition: all 0.5s ease;

  @media (max-width: 660px) {
    border-radius: 24px;
  }

  @media (max-width: 460px) {
    height: 56px;
    margin-top: 44px;
    border-radius: 80px;
  }
`;

export const Values = styled.p`
  left: 0;
  top: -28px;
  color: white;
  opacity: 0.8;
  font-size: 12px;
  font-weight: 500;
  position: absolute;

  @media (max-width: 460px) {
    font-size: 11px;
  }
`;

export const ProgressBarFill = styled.div<{ completed: number }>`
  ${({ completed }) => css`
    height: 100%;
    display: flex;
    flex-shrink: 0;
    border-radius: 24px;
    align-items: center;
    width: ${completed}%;
    justify-content: center;
    background-color: #de0648;
    transition: all 0.5s ease-in-out;

    @media (max-width: 460px) {
      border-radius: 80px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    span {
      color: white;
      font-size: 16px;
      font-weight: 600;

      ${completed <= 10 ? "display: none;" : ""}

      @media (max-width: 660px) {
        ${completed <= 15 ? "display: none;" : ""}
      }

      @media (max-width: 500px) {
        ${completed <= 20 ? "display: none;" : ""}
      }

      @media (max-width: 460px) {
        font-size: 14px;
      }

      @media (max-width: 420px) {
        ${completed <= 25 ? "display: none;" : ""}
      }

      @media (max-width: 360px) {
        ${completed <= 35 ? "display: none;" : ""}
      }
    }
  `}
`;

export const ProgressBarText = styled.div<{ completed: number }>`
  ${({ completed }) => css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      font-weight: 600;

      ${completed >= 90 ? "display: none;" : ""}

      @media (max-width: 660px) {
        ${completed >= 85 ? "display: none;" : ""}
      }

      @media (max-width: 500px) {
        ${completed >= 80 ? "display: none;" : ""}
      }

      @media (max-width: 460px) {
        font-size: 14px;
      }

      @media (max-width: 420px) {
        ${completed >= 75 ? "display: none;" : ""}
      }

      @media (max-width: 360px) {
        ${completed >= 65 ? "display: none;" : ""}
      }
    }
  `}
`;

export const Indicator = styled.div<{ todayGoal: number }>`
  ${({ todayGoal }) => css`
    gap: 4px;
    top: -24px;
    height: 136px;
    flex-wrap: nowrap;
    position: absolute;
    border-radius: 4px;
    flex-direction: column;
    background-color: tomato;
    transform: translateX(-1px);
    transition: transform 0.5s ease-in-out;

    ${todayGoal < 50 ? `left: ${todayGoal}%` : `right: ${100 - todayGoal}%`};

    @media (max-width: 460px) {
      top: -20px;
      height: 104px;
    }

    div {
      width: 2px;
      height: 100%;
      background-color: #333;
    }

    label {
      color: #666;
      bottom: -20px;
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      white-space: nowrap;
      transform: translateX(
        ${todayGoal <= 30 ? "0%" : todayGoal >= 70 ? "-100%" : "-50%"}
      );
    }
  `}
`;
