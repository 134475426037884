import { formatDistanceInKilometers } from "../../../utils";
import * as S from "./styles";

interface IHomePageProgressBar {
  todayGoal: number;
  totalGoal: number;
  totalDistance: number;
  finalPercentage: number;
  pendingDistance: number;
}

const HomePageProgressBar: React.FC<IHomePageProgressBar> = ({
  totalGoal,
  todayGoal,
  totalDistance,
  finalPercentage,
  pendingDistance,
}) => {
  return (
    <S.HomePageProgressBar>
      <S.Values>{`${(totalDistance / 1000).toFixed(
        0,
      )}/${totalGoal}km`}</S.Values>

      <S.ProgressBarFill completed={finalPercentage}>
        <span>{formatDistanceInKilometers(totalDistance, 0)}</span>
      </S.ProgressBarFill>

      <S.ProgressBarText completed={finalPercentage}>
        <span>{formatDistanceInKilometers(pendingDistance * 1000, 0)}</span>
      </S.ProgressBarText>

      <S.Indicator todayGoal={todayGoal}>
        <div />
        <label>Deveríamos estar aqui</label>
      </S.Indicator>
    </S.HomePageProgressBar>
  );
};

export default HomePageProgressBar;
