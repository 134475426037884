import styled from "styled-components";

export const ActivityCards = styled.div`
  gap: 12px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  padding-bottom: 80px;
  flex-direction: column;

  @media (max-width: 460px) {
    gap: 8px;
    padding: 0 12px 80px 12px;
  }
`;

export const Options = styled.div`
  width: 100%;
  max-width: 680px;
  align-self: center;
  padding: 0 20px 12px 20px;
`;

export const Sort = styled.div`
  gap: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
`;

export const Button = styled.button<{ highlight: boolean }>`
  cursor: pointer;
  font-size: inherit;
  color: ${({ highlight }) => (highlight ? "#074899" : "#333")};
  font-weight: ${({ highlight }) => (highlight ? "600" : "400")};
  text-decoration: ${({ highlight }) => (highlight ? "underline" : "none")};
`;

export const Totalizers = styled.div`
  width: 100%;
  max-width: 680px;
  padding: 32px 20px;
  align-self: center;

  @media (max-width: 460px) {
    gap: 8px;
    padding: 20px 12px;
  }
`;
