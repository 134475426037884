export const STRAVA_OAUTH_URL = [
  "https://www.strava.com/oauth/authorize",
  "?client_id=118676",
  "&redirect_uri=https://play9.run/login",
  "&response_type=code",
  "&approval_prompt=auto",
  "&scope=profile:read_all,activity:read_all",
].join("");

export const INVALID_SCOPE_ERROR_MESSAGE = [
  "Para usar o app, você precisa autorizar todos os dados solicitados no Strava.",
  "Clique em conectar novamente e certifique-se de que todos os campos estão sinalizados.",
].join(" ");
