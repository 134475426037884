/* eslint-disable @typescript-eslint/no-empty-function */
import { IAthlete } from "../types/athlete";
import React, { createContext, useContext, useState } from "react";

const emptyContext: {
  isLogged: boolean;
  logout: () => void;
  user: IAthlete | null;
  login: (data: IAthlete) => void;
} = {
  user: null,
  isLogged: false,
  login: () => {},
  logout: () => {},
};

const Context = createContext(emptyContext);

const useAuth = () => useContext(Context);

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IAthlete | null>(
    JSON.parse(localStorage.getItem("@user") || "null"),
  );

  const login = (data: IAthlete) => {
    setUser(data);
    localStorage.setItem("@user", JSON.stringify(data));
  };

  const logout = () => {
    setUser(emptyContext.user);
    localStorage.removeItem("@user");
  };

  const providerValue = {
    user,
    login,
    logout,
    isLogged: !!user,
  };

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export default {
  useAuth,
  Provider,
};
