import axios from "axios";
import { IAthlete } from "../types/athlete";
import { ICurrentChallengeResponse, IGetAthleteResponse } from "../types/api";

export const auth = async (code: string): Promise<IAthlete> => {
  const { data } = await axios.post(
    "https://api.play9.run/v1/auth",
    { code },
    { headers: { "Content-Type": "application/json" } },
  );

  const { athlete } = data || {};

  return athlete as IAthlete;
};

export const getCurrentChallenge =
  async (): Promise<ICurrentChallengeResponse> => {
    const { data } = await axios.get(
      "https://api.play9.run/v1/current/challenge",
    );

    return data as ICurrentChallengeResponse;
  };

export const getAthlete = async (id: string): Promise<IGetAthleteResponse> => {
  const { data } = await axios.get(`https://api.play9.run/v1/athlete/${id}`);

  return data as IGetAthleteResponse;
};
