import Home from "../../components/pages/Home";
import Athlete from "../../components/pages/Athelete";
import { Navigate, Route, Routes } from "react-router-dom";

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/athelete/:id" element={<Athlete />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PrivateRoutes;
